import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Application imports
import { backend } from '../authConfig';
import { useAppState } from '../contexts/AppStateContext';
import CheckoutInternationalForm from "./CheckoutInternationalForm";

const loadingMessages = [
  'Fueling rocket...Please wait...',
  'Calibrating laser guns...Please wait...',
  'Assembling robots...Please wait...',
  'Loading the hyperdrive...Please wait...',
  'Tuning the teleporter...Please wait...',
  'Switching on the G Force ...',
  'Preparing wood to warm up the water... Please wait...',
  'Mixing ingredients to make soap ... Please wait',
  'May for the Force be with you while you wait ...'
]

const LoadingComponent = () => {
  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prevMessage) => (prevMessage + 1) % loadingMessages.length);
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="international-checkout-messages">
      {loadingMessages[currentMessage]}
    </div>
  );
};

// Stripe payments 
function CheckoutStripe() {
  const { cycles } = useAppState();
  const { sessionUUID } = useAppState();

  const [ stripePromise, setStripePromise ] = useState(null);
  const [ clientSecret, setClientSecret ] = useState(null);
  const { locationId, productNumber } = useParams();
  const formattedLocationId = locationId.toString().padStart(2, '0');
  const formattedProductNumber = productNumber.toString().padStart(2, '0');

  useEffect(() => {
    fetch(backend.url_get_payrequest_config_stripe).then(async (r) => {
      const { publishableKey } = await r.json();
      console.log("PaymentStripe publishableKey:", publishableKey);
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    if (!clientSecret) {
      const requestBody = {
        session_uuid: sessionUUID,
        machines: [
          {
            laundry_id: formattedLocationId,
            machine_id: formattedProductNumber,
            number_of_impulses: cycles
          }
        ],
        debug: "1" // Assuming you want to keep this for debugging
      };

      fetch(backend.url_post_payrequest_stripe, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      }).then(async (result) => {
        var { clientSecret } = await result.json();
        console.log("PaymentStripe clientSecret:", clientSecret);
        setClientSecret(clientSecret);
      });
    }
  }, [
      cycles,
      formattedLocationId, formattedProductNumber,
      clientSecret,
      sessionUUID
    ]);

  return (
    <>
      {clientSecret && stripePromise ? (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutInternationalForm />
        </Elements>
      ) : (
        // This will be shown while waiting for clientSecret and stripePromise
        <LoadingComponent />
      )}
    </>
  );
}

export default CheckoutStripe;
