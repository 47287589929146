// Faq.jsx
import React from 'react';
import { Container, Accordion } from 'react-bootstrap';
import faqData from './faqData.json'; 

const Faq = () => {
  return (
    <Container>
      <h1>Frequently Asked Questions (FAQ)</h1>
      <Accordion defaultActiveKey="0">
        {faqData.map((item, index) => (
          <Accordion.Item eventKey={index.toString()} key={index}>
            <Accordion.Header>{item.question}</Accordion.Header>
            <Accordion.Body>{item.answer}</Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
};

export default Faq;