import React from 'react';

function GoogleMapsLink({ googleMapsLink, googleMapsText }) {
  // Check if both link and text are present
  const hasGoogleMapsData = googleMapsLink && googleMapsText;

  if (!hasGoogleMapsData) {
    return null; // Render nothing if the data is not available
  }

  return (
    <div className="location-id">
      <h3 className="card-subtitle black-subtitle">
        <a
          href={googleMapsLink}
          target="_blank"
          rel="noopener noreferrer"
          className="google-maps-link"
        >
          {googleMapsText}
        </a>
      </h3>
    </div>
  );
}

export default GoogleMapsLink;