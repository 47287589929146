// ./contexts/AppConfigContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchConfig } from '../getConfig'; // Adjusted to fetch config independently
import { getAllProductsArray } from './ProductData'; // Fetch product data
import { fetchLocations } from '../lib/getLocationData'; // Fetch location data

export const AppConfigContext = createContext(null); // Ensure explicit export

export const AppConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);
  const [productData, setProductData] = useState(null);
  const [locations, setLocations] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAppData = async () => {
      try {
        setIsLoading(true);
        const [configData, locationData, productDataArray] = await Promise.all([
          fetchConfig(),
          fetchLocations(),
          getAllProductsArray()
        ]);
        setConfig(configData);
        setLocations(locationData);
        setProductData(productDataArray);
      } catch (error) {
        console.error('Error fetching app data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAppData();
  }, []);

  return (
    <AppConfigContext.Provider value={{ config, locations, productData, isLoading }}>
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfig = () => {
  const context = useContext(AppConfigContext);
  if (!context) {
    throw new Error('useAppConfig must be used within an AppConfigProvider');
  }
  return context;
};
