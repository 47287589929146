import { Button, Card } from 'react-bootstrap';
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useParams } from 'react-router-dom';

import useProduct from '../hooks/useProduct';
import { useAppState } from '../contexts/AppStateContext';
import { useAppConfig } from '../contexts/AppConfigContext';

function CheckoutInternationalForm() {
  const { cycles } = useAppState(); 
  const { isLoading } = useAppConfig(); 
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const { locationId, productNumber } = useParams();
  const product = useProduct(locationId, productNumber);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!product) {
    return <div>Loading ...</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        //return_url: `${window.location.origin}/success/${product.type.toLowerCase()}`,
        // Added locationId so that we can pass it to the success page
        return_url: `${window.location.origin}/success/${product.type.toLowerCase()}?locationId=${locationId}&machineId=${productNumber}&paymentMethod=stripe`,
      },
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Card className="product-card">
        <Card.Body>
          <Card.Title>International Checkout</Card.Title>
          <Card.Subtitle className="mb-2">
            {product.title} {product.number} - {product.capacity}Kg
          </Card.Subtitle>
          <Card.Text>€{product.price * cycles}</Card.Text>
          <p>Payments powered by Stripe. We do not store payment data.</p>
        </Card.Body>
      </Card>

      <PaymentElement id="payment-element" />

      <Button
        variant="primary"
        type="submit"
        className="mt-3"
        disabled={isProcessing || !stripe}
      >
        <span id="button-text">
          {isProcessing ? "Processing..." : "Pay now"}
        </span>
      </Button>

      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

export default CheckoutInternationalForm;