import { Card, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { fetchConfig } from '../getConfig';
import { fetchLocations } from '../lib/getLocationData';
import { useAppState } from '../contexts/AppStateContext';

import useProduct from '../hooks/useProduct';
import useChatwoot from '../hooks/useChatwoot';

function PaymentMethodOptions() {
  const fName = 'PaymentMethodOptions';
  const logPrefix = `<${fName}>`;

  const { locationId, productNumber } = useParams();
  const product = useProduct(locationId, productNumber);

  const [appConfig, setAppConfig] = useState(null);
  const [locations, setLocations] = useState([]);
  const { 
    cycles,
    updateLocationId,
    locationId: currentLocationId,
    updateMachineId,
    machineId: currentMachineId,
    updatePaymentMethod,
  } = useAppState();


  const navigate = useNavigate();

  useEffect(() => {
    async function loadConfig() {
      try {
        const config = await fetchConfig();
        setAppConfig(config);
      } catch (error) {
        console.error("Error fetching app configuration:", error);
      }
    }

    loadConfig();
  }, []);

  useEffect(() => {
    const getLocations = async () => {
      const locationsData = await fetchLocations();
      setLocations(locationsData);
    };

    getLocations();
  }, []);

  useEffect(() => {
    if (locationId && currentLocationId !== locationId) {
      updateLocationId(locationId);
    }
    if (productNumber && currentMachineId !== productNumber) {
      updateMachineId(productNumber);
    }
  }, [locationId, currentLocationId, updateLocationId, productNumber, currentMachineId, updateMachineId]);
   

  useChatwoot("", "", "", locationId);

  const handlePaymentMethodClick = (productNumber, paymentMethod) => {
    console.log(logPrefix, 'handleCartClick productNumber:', productNumber);
    
    // Updates the payment method in the state
    updatePaymentMethod(paymentMethod);
    
    if (paymentMethod === 'mbway') {
      navigate(`/checkout-details-mbway/${locationId}/${productNumber}`);
    } else if (paymentMethod === 'stripe') {
      navigate(`/checkout-details-stripe/${locationId}/${productNumber}`);
    } else {
      navigate(`/checkout-details-stripe/${locationId}/${productNumber}`);
    }
  };

  if (!product || !appConfig || !locations) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <h4 className="p-1 white-text">Escolha método de pagamento:</h4>

      <Card.Body>
        <Card.Subtitle className="mb-1">{product.title} {product.number} - {product.capacity}Kg</Card.Subtitle>
        <Card.Text>€{product.price * cycles}</Card.Text>
      </Card.Body>
      <br />

      <Card className="payment-method-card">
        <Card.Body onClick={() => handlePaymentMethodClick(product.number, "mbway")} style={{ cursor: 'pointer' }}>
          <Row className="align-items-center">
            <Col xs={8}>MBWay</Col>
            <Col xs={4} className="payment-images">
              <img className="payment-logo" src="/logo_mbway.png" alt="MBWay Logo" />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Stripe Payment */}
      {locations && (
      locations.find(location => location.laundry_id === String(locationId))?.stripe_flag === 1 && (
        <Card className="payment-method-card">
          <Card.Body onClick={() => handlePaymentMethodClick(product.number, "stripe")} style={{ cursor: 'pointer' }}>
            <Row className="align-items-center">
              <Col xs={8}>
                Cartão Crédito<br/>
                Google Pay<br/>
                Outros
              </Col>
              <Col xs={4} className="payment-images">
                <img className="payment-logo" src="/logo_visa.jpeg" alt="Card Logo"/>
                <img className="payment-logo" src="/logo_mastercard.png" alt="Google Pay Logo"/>
                <img className="payment-logo" src="/logo_gpay.png" alt="Google Pay Logo"/>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )
      )}

      {/* Bitcoin Payment Card */}
      {locations && locations.find(location => location.laundry_id === String(locationId))?.stripe_flag === 1 && (
        <Card className="payment-method-card">
          <Card.Body style={{ cursor: 'default' }}> {/* Removed onClick and set cursor to default */}
            <Row className="align-items-center">
              <Col xs={8}>
                Bitcoin (Brevemente / Soon)<br/>
              </Col>
              <Col xs={4} className="payment-images">
                <img className="payment-logo" src="/logo_bitcoin.png" alt="Bitcoin Logo"/>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}



    </>
  );
}

export default PaymentMethodOptions;
