// ./src/components/WhatsAppButton.jsx
import React from 'react';

function WhatsAppButton({ phoneNumber, locationId, locationName, machineId, paymentMethod }) {
  if (!phoneNumber || !locationId || !locationName || !machineId || !paymentMethod) {
    return null; // If required props are missing, render nothing
  }

  let paymentDescription = 'outro';
  if (paymentMethod.toLowerCase() === 'mbway') {
    paymentDescription = 'MBWay';
  }
  else if (paymentMethod.toLowerCase() === 'stripe') {
    paymentDescription = 'Stripe';
  }

  const message = `Olá! Acabei de fazer uma compra na lavandaria ${locationName}, maquina ${machineId}, com pagamento ${paymentDescription} e preciso de apoio.`;

  const encodedMessage = encodeURIComponent(message);
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return (
    <div className="whatsapp-button-container" style={{ marginTop: '20px' }}>
      <a
        href={whatsappUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp-link"
        style={{
          display: 'inline-block',
          textDecoration: 'none',
          padding: '10px 15px',
          backgroundColor: '#25D366',
          color: '#fff',
          borderRadius: '5px',
          fontWeight: 'bold'
        }}
      >
        Apoio WhatsApp
      </a>
    </div>
  );
}

export default WhatsAppButton;