/*
 * Filename: application/frontend/src/components/CheckoutMbWay.jsx
 * Description: CheckoutMbWay component
 * Author: Aires Marques
 * History:
 + 2023-10-22 - Checkout MbWay documented
 + 2024-12-10 - Added AppConfigContext import and locations
 * Notes:
*/

import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { isValidPhoneNumber as validatePhoneNumber } from 'libphonenumber-js'; 
import { validateEmail, validateNif } from '../lib/validations';

// Application components
import CheckoutMbWayModal from './CheckoutMbWayModal'; 
import { useAppState } from '../contexts/AppStateContext';
import { useAppConfig } from '../contexts/AppConfigContext'; 
import useProduct from '../hooks/useProduct';
import { usePaymentProcessing } from '../hooks/useMbWayPaymentProcessing';
import setChatCustomAttributes from '../services/setChatCustomAttributes';

/**
 * CheckoutMbWay component: Provides the MBWay checkout form to the user and handles
 * the payment request, polling for payment confirmation, and machine activation.
 */
function CheckoutMbWay() {
  const { locationId, productNumber } = useParams();
  const product = useProduct(locationId, productNumber);
  const { cycles } = useAppState();
  const { locations } = useAppConfig(); // Use AppConfigContext to get locations

  // Extract location definition data from locations
  const locationDefinitionData = locations?.find(loc => loc.laundry_id === locationId);

  // Form fields
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem('phoneNumber') || '');
  const [nif, setNif] = useState(localStorage.getItem('nif') || '');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [name, setName] = useState(localStorage.getItem('name') || '');

  // Validation states
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [isValidNif, setIsValidNif] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const {
    paymentRequested, setPaymentRequested,
    paymentConfirmed, setPaymentConfirmed,
    paymentFailed, setPaymentFailed,
    submitPaymentRequest,
    pollPaymentConfirmation,
  } = usePaymentProcessing({ locationId, productNumber, phoneNumber, nif, email, name });

  const navigate = useNavigate();

  // Sync form fields with localStorage
  useEffect(() => {
    localStorage.setItem('phoneNumber', phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    localStorage.setItem('nif', nif);
  }, [nif]);

  useEffect(() => {
    localStorage.setItem('email', email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem('name', name);
  }, [name]);

  // Validate phone number
  useEffect(() => {
    setIsValidPhoneNumber(phoneNumber ? validatePhoneNumber(phoneNumber) : false);
  }, [phoneNumber]);

  // Validate email (handle if validateEmail returns a promise)
  useEffect(() => {
    if (email) {
      validateEmail(email).then(valid => setIsValidEmail(valid));
    } else {
      setIsValidEmail(false);
    }
  }, [email]);

  // Validate NIF
  useEffect(() => {
    setIsValidNif(nif ? validateNif(nif) : false);
  }, [nif]);

  // Set chat attributes on payment confirmation
  useEffect(() => {
    if (paymentConfirmed) {
      setChatCustomAttributes(locationId, productNumber);
    }
  }, [paymentConfirmed, locationId, productNumber]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setPaymentRequested(false);
    setPaymentConfirmed(false);
    setPaymentFailed(false);

    const invalidReasons = [];
    if (phoneNumber && !isValidPhoneNumber) {
      invalidReasons.push("Phone number is invalid");
    }
    if (nif && !isValidNif) {
      invalidReasons.push("NIF is invalid");
    }
    if (email && !isValidEmail) {
      invalidReasons.push("Email is invalid");
    }

    if (invalidReasons.length > 0) {
      console.log('Invalid form due to:', invalidReasons.join(', '));
      return;
    }

    setShowModal(true);

    try {
      const paymentId = await submitPaymentRequest();
      pollPaymentConfirmation(paymentId);
    } catch (error) {
      console.error('Payment submission error:', error);
    }
  };

  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Card className="product-card">
        <Card.Body>
          <Card.Title>MBWay Checkout</Card.Title>
          <Card.Subtitle className="mb-2">
            {product.title} {product.number} - {product.capacity}Kg
          </Card.Subtitle>
          <Card.Text>€{product.price * cycles}</Card.Text>

          <Form onSubmit={handleSubmit}>
            <Row style={{ marginBottom: '1rem' }}>
              <Col>
                <Form.Group controlId="phone">
                  <Form.Label>Phone Number (obrigatório):</Form.Label>
                  <PhoneInput
                    international
                    placeholder="Introduza o seu número de telefone"
                    defaultCountry="PT"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    className="phone-input"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            {locationDefinitionData && locationDefinitionData.office_gest_flag === 1 && (
              <>
                <Row style={{ marginBottom: '1rem' }}>
                  <Col>
                    <Form.Group controlId="nif">
                      <Form.Label>NIF (opcional para fatura)</Form.Label>
                      <Form.Control
                        type="text"
                        value={nif}
                        onChange={(e) => setNif(e.target.value)}
                      />
                      {nif && !isValidNif && (
                        <Form.Text className="text-danger">
                          Introduza um NIF válido.
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ marginBottom: '1rem' }}>
                  <Col>
                    <Form.Group controlId="email">
                      <Form.Label>Email (opcional para fatura)</Form.Label>
                      <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {email && !isValidEmail && (
                        <Form.Text className="text-danger">
                          Introduza um email válido.
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row style={{ marginBottom: '1rem' }}>
                  <Col>
                    <Form.Group controlId="name">
                      <Form.Label>Nome (opcional para fatura):</Form.Label>
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            <Button
              variant="primary"
              type="submit"
              className="mt-3"
              disabled={
                !isValidPhoneNumber ||
                (nif && !isValidNif) ||
                (email && !isValidEmail)
              }
            >
              Finalizar
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {showModal && (
        <CheckoutMbWayModal
          showModal={showModal}
          handleCloseModal={() => {
            setShowModal(false);
            if (paymentConfirmed) {
              if (product.type === 'DRY') {
                navigate(`/success/dry`);
              } else {
                navigate(`/success/wash`);
              }
            }
          }}
          handleCloseModalAndNavigate={() => {
            setShowModal(false);
            if (paymentConfirmed) {
              if (product.type === 'DRY') {
                navigate(`/success/dry`);
              } else {
                navigate(`/success/wash`);
              }
            }
          }}
          paymentRequested={paymentRequested}
          paymentConfirmed={paymentConfirmed}
          paymentFailed={paymentFailed}
        />
      )}
    </>
  );
}

export default CheckoutMbWay;