// ./src/pages/SuccessWash.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GoogleMapsLink from '../components/GoogleMapsLink';
import { useAppConfig } from '../contexts/AppConfigContext';
import { useAppState } from '../contexts/AppStateContext';
import WhatsAppButton from '../components/WhatsAppButton'; // Import the new component

function SuccessWash() {
  const { locations, isLoading } = useAppConfig();
  const { locationId, updateLocationId, machineId, updateMachineId, paymentMethod, updatePaymentMethod } = useAppState();
  const urlParams = new URLSearchParams(useLocation().search);
  const urlLocationId = urlParams.get('locationId');
  const urlMachineId = urlParams.get('machineId');
  const urlPaymentMethod = urlParams.get('paymentMethod');

  useEffect(() => {
    if (urlLocationId && locationId !== urlLocationId) {
      updateLocationId(urlLocationId);
    }
    if (urlMachineId && machineId !== urlMachineId) {
      updateMachineId(urlMachineId);
    }
    if (urlPaymentMethod && paymentMethod !== urlPaymentMethod) {
      updatePaymentMethod(urlPaymentMethod);
    }
  }, [
    urlLocationId, locationId, updateLocationId,
    urlMachineId, machineId, updateMachineId,
    urlPaymentMethod, paymentMethod, updatePaymentMethod]);

  if (isLoading) {
    return <div>Loading configuration...</div>;
  }

  const matchedLocation = locations?.find((loc) => loc.laundry_id === locationId);

  return (
    <div style={{ padding: '20px' }}>
      <h2 className="card-text black-subheading">Instruções:</h2>
      
      <ul className="card-text black-list">
        <li>Coloque a roupa na máquina e feche a porta.</li>
        <li>Selecione o programa de lavagem e pressione “Start”.</li>
      </ul>
  
      <h1 className="card-subtitle black-subtitle">
        Boa Lavagem 🎉
      </h1>

      <GoogleMapsLink
        googleMapsLink={matchedLocation?.google_maps_link}
        googleMapsText={matchedLocation?.google_maps_text}
      />

      {/* Add WhatsApp button if location provides a phone number or a known default */}
      <WhatsAppButton
        phoneNumber={matchedLocation?.owner_whatsapp} 
        locationId={locationId}
        locationName={matchedLocation?.name}
        machineId={machineId}
        paymentMethod={paymentMethod}
      />
    </div>
  );
}

export default SuccessWash;