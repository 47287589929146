// ./contexts/AppStateContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const AppStateContext = createContext(null);

const initialState = {
  paymentMethod: null,
  cycles: 1,
  locationId: null, // Location ID added for global state management
  machineId: null,
  sessionUUID: null,
};

export const AppStateProvider = ({ children }) => {
    const [state, setState] = useState(() => {
        const storedUuid = localStorage.getItem('sessionUUID') || uuidv4();
        return {
            ...initialState,
            sessionUUID: storedUuid,
        };
    });

    useEffect(() => {
        if (!localStorage.getItem('sessionUUID')) {
            localStorage.setItem('sessionUUID', state.sessionUUID);
        }
    }, [state.sessionUUID]);

    const updateLocationId = (locationId) => {
        setState((prevState) => ({ ...prevState, locationId }));
    };

    const updateMachineId = (machineId) => {
        setState((prevState) => ({ ...prevState, machineId }));
    };

    const updatePaymentMethod = (paymentMethod) => {
        setState((prevState) => ({ ...prevState, paymentMethod }));
    }



    return (
        <AppStateContext.Provider value={{ ...state, setState, updateLocationId, updateMachineId, updatePaymentMethod }}>
            {children}
        </AppStateContext.Provider>
    );
};

export const useAppState = () => {
    const context = useContext(AppStateContext);
    if (!context) {
        throw new Error('useAppState must be used within an AppStateProvider');
    }
    return context;
};
